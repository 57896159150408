import React from "react"
import Page from "../components/page"
import ResponsiveImage from "../components/responsive-image"
import ResponsiveImageGrid from "../components/responsive-image-grid"
import ScrollDown from "../components/scroll-down"
import { themes } from "../styles/themes"
import paths from "../utils/paths"
import useScreenSize from "../utils/useScreenSize"

export default function Author({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.author}
      description={labels.authorDescription}
      keywords={labels.authorKeywords}
      ogUrl={paths(labels).author}
      ogImage="/images/og-images/author.jpg"
      footerPadding={true}
      headerPadding={isSmallScreen}
    >
      {isSmallScreen ? (
        <div className="author-container">
          <div className="author-ips-container">
            <p className="author-every-space-text">
              {labels.authorEverySpaceText}
            </p>
            <br />
            <p>{labels.authorIgorProdanovicText}</p>
            <ResponsiveImage
              src="/images/author/igor-prodanovic.jpg"
              containerClass="img-container author-igor-prodanovic-img-container"
              alt={labels.authorIgorProdanovicAlt}
              imgTitle={labels.authorIgorProdanovicImgTitle}
            />
            <p>
              <span className="author-ip-text">{labels.authorIPText}</span>
              <span className="author-s-text">{labels.authorSText}</span>
              <span>{labels.authorIPSText}</span>
            </p>
            <ResponsiveImageGrid
              gridClass="author-conceptual-solutions-grid-container"
              grid={[
                {
                  type: "grid",
                  orientation: "vertical",
                  margin: false,
                  grid: [
                    {
                      type: "image",
                      weight: 49.25,
                      src: "/images/author/conceptual-solutions.jpg",
                      alt: labels.authorConceptualSolutionsBlackWhiteAlt,
                      imgTitle:
                        labels.authorConceptualSolutionsBlackWhiteImgTitle
                    },
                    {
                      type: "image",
                      weight: 49.25,
                      src: "/images/author/conceptual-solutions-drawings.jpg",
                      alt: labels.authorConceptualSolutionsColorAlt,
                      imgTitle: labels.authorConceptualSolutionsColorImgTitle
                    }
                  ]
                }
              ]}
            />
          </div>
          <div className="author-interiors-container">
            <div className="author-interiors-text-container">
              <p>
                <span className="author-interiors-text">
                  {labels.authorInteriorsText}
                </span>
                <span className="author-space-with-signature-text">
                  {labels.authorInteriorsSpaceWithSignatureText}
                </span>
              </p>
              <br />
              <p>{labels.authorInteriorsDesirableDesignText}</p>
              <br />
              <p>{labels.authorInteriorsCreatingText}</p>
              <br />
              <p>{labels.authorInteriorsDesignedSpacesText}</p>
              <br />
              <p>{labels.authorInteriorsPrinciplesText}</p>
              <br />
            </div>
            <ResponsiveImage
              src="/images/author/interiors-space-for-pleasure.jpg"
              containerClass="author-interiors-space-for-pleasure-img-container"
              alt={labels.authorInteriorsSpaceForPleasureAlt}
              imgTitle={labels.authorInteriorsSpaceForPleasureImgTitle}
            />
          </div>
          <div className="author-product-design-container">
            <div className="author-product-design-text-container">
              <p className="author-product-design-text">
                {labels.authorProductDesignText}
              </p>
              <br />
              <p>{labels.authorProductDesignLovePassionText}</p>
              <br />
              <p>{labels.authorProductDesignStoryText}</p>
              <br />
              <p>{labels.authorProductDesignDetailsText}</p>
              <br />
            </div>
            <ResponsiveImage
              src="/images/author/product-design-007-bar-detail.jpg"
              alt={labels.authorProductDesign007BarAlt}
              imgTitle={labels.authorProductDesign007BarImgTitle}
            />
            <div className="author-product-design-text-container">
              <br />
              <p>{labels.authorProductDesignOnePieceText}</p>
              <br />
              <p>{labels.authorProductDesignAuthenticText}</p>
              <br />
            </div>
            <ResponsiveImage
              src="/images/author/55-zg-salon.jpg"
              alt={labels.authorProductDesign55ZgSalonAlt}
              imgTitle={labels.authorProductDesign55ZgSalonImgTitle}
            />
            <div className="author-product-design-text-container">
              <br />
              <p>{labels.authorProductDesign55ZgSalonText}</p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <ScrollDown />
          <ResponsiveImage
            src="/images/author/igor-prodanovic-mirror-eye.jpg"
            mode="full-screen"
            position="right"
            alt={labels.authorIgorProdanovicMirrorAlt}
            imgTitle={labels.authorIgorProdanovicMirrorImgTitle}
            text={labels.authorEverySpaceText}
            textContainerClass="author-every-space-text-container"
            textClass="author-every-space-text"
            textElement={
              <div className="author-photo-text-container">
                <p className="author-photo-text">
                  {labels.authorPhotographyText}
                </p>
              </div>
            }
          />
          <div className="author-ips-container">
            <ResponsiveImage
              src="/images/author/ips-white.jpg"
              containerClass="img-container author-ips-img-container"
              alt={labels.authorIPSAlt}
              imgTitle={labels.authorIPSImgTitle}
            />
            <div className="author-ips-contents-container">
              <div className="author-ips-text-container">
                <p>
                  <span className="author-ip-text">{labels.authorIPText}</span>
                  <span className="author-s-text">{labels.authorSText}</span>
                  <span>{labels.authorIPSText}</span>
                </p>
                <br />
                <p>{labels.authorIgorProdanovicText}</p>
                <br />
              </div>
              <ResponsiveImageGrid
                gridClass="author-conceptual-solutions-grid-container"
                grid={[
                  {
                    type: "grid",
                    orientation: "horizontal",
                    margin: false,
                    grid: [
                      {
                        type: "image",
                        weight: 49.25,
                        src: "/images/author/conceptual-solutions.jpg",
                        alt: labels.authorConceptualSolutionsBlackWhiteAlt,
                        imgTitle:
                          labels.authorConceptualSolutionsBlackWhiteImgTitle
                      },
                      {
                        type: "image",
                        weight: 49.25,
                        src: "/images/author/conceptual-solutions-drawings.jpg",
                        alt: labels.authorConceptualSolutionsColorAlt,
                        imgTitle: labels.authorConceptualSolutionsColorImgTitle
                      }
                    ]
                  }
                ]}
              />
            </div>
          </div>
          <div className="author-interiors-container">
            <div className="author-interiors-text-container">
              <p>
                <span className="author-interiors-text">
                  {labels.authorInteriorsText}
                </span>
                <span className="author-space-with-signature-text">
                  {labels.authorInteriorsSpaceWithSignatureText}
                </span>
              </p>
              <br />
              <p>{labels.authorInteriorsDesirableDesignText}</p>
              <br />
              <p>{labels.authorInteriorsCreatingText}</p>
              <br />
              <p>{labels.authorInteriorsDesignedSpacesText}</p>
              <br />
              <p>{labels.authorInteriorsPrinciplesText}</p>
            </div>
            <ResponsiveImage
              src="/images/author/interiors-space-for-pleasure.jpg"
              containerClass="author-interiors-space-for-pleasure-img-container"
              alt={labels.authorInteriorsSpaceForPleasureAlt}
              imgTitle={labels.authorInteriorsSpaceForPleasureImgTitle}
            />
          </div>
          <div className="author-product-design-container">
            <div className="author-product-design-images-container">
              <ResponsiveImageGrid
                gridClass="author-product-design-images-grid-container"
                grid={[
                  {
                    type: "grid",
                    orientation: "vertical",
                    margin: false,
                    grid: [
                      {
                        type: "image",
                        weight: 49.25,
                        src: "/images/author/product-design-007-bar-detail.jpg",
                        alt: labels.authorProductDesign007BarAlt,
                        imgTitle: labels.authorProductDesign007BarImgTitle
                      },
                      {
                        type: "image",
                        weight: 49.25,
                        src: "/images/author/55-zg-salon.jpg",
                        description: labels.authorProductDesign55ZgSalonText,
                        alt: labels.authorProductDesign55ZgSalonAlt,
                        imgTitle: labels.authorProductDesign55ZgSalonImgTitle
                      }
                    ]
                  }
                ]}
              />
            </div>
            <div className="author-product-design-text-container">
              <p className="author-product-design-text">
                {labels.authorProductDesignText}
              </p>
              <br />
              <p>{labels.authorProductDesignLovePassionText}</p>
              <br />
              <p>{labels.authorProductDesignStoryText}</p>
              <br />
              <p>{labels.authorProductDesignDetailsText}</p>
              <br />
              <p>{labels.authorProductDesignOnePieceText}</p>
              <br />
              <p>{labels.authorProductDesignAuthenticText}</p>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
